/*
 Margin and rounding are personal preferences,
 overflow-x-auto is recommended.
*/
.torchlight {
  @apply !px-0 my-4 rounded overflow-x-auto;
}

/*
 Add some vertical padding and expand the width
 to fill its container. The horizontal padding
 comes at the line level so that background
 colors extend edge to edge.
*/
.torchlight {
  @apply block py-4;
}

/*
 Horizontal line padding.
*/
.torchlight .line {
  @apply px-8;
}

.torchlight.has-summaries .line {
	@apply !px-2; /* Override the default padding */
}

/*
 Push the code away from the line numbers and
 summary caret indicators.
*/
.torchlight .line-number,
.torchlight .summary-caret {
  @apply mr-4;
}

.torchlight summary:focus {
  outline: none;
}

/* Hide the default markers, as we provide our own */
.torchlight details > summary::marker,
.torchlight details > summary::-webkit-details-marker {
  display: none;
}

.torchlight details .summary-caret::after {
  pointer-events: none;
}

/* Add spaces to keep everything aligned */
.torchlight .summary-caret-empty::after,
.torchlight details .summary-caret-middle::after,
.torchlight details .summary-caret-end::after {
  content: " ";
}

/* Show a minus sign when the block is open. */
.torchlight details[open] .summary-caret-start::after {
  content: "-";
}

/* And a plus sign when the block is closed. */
.torchlight details:not([open]) .summary-caret-start::after {
  content: "+";
}

/* Hide the [...] indicator when open. */
.torchlight details[open] .summary-hide-when-open {
  display: none;
}

/* Show the [...] indicator when closed. */
.torchlight details:not([open]) .summary-hide-when-open {
  display: initial;
}

/*
  Blur and dim the lines that don't have the `.line-focus` class,
  but are within a code block that contains any focus lines.
*/
.torchlight.has-focus-lines .line:not(.line-focus) {
  transition: filter 0.35s, opacity 0.35s;
  filter: blur(.095rem);
  opacity: .65;
}

/*
  When the code block is hovered, bring all the lines into focus.
*/
.torchlight.has-focus-lines:hover .line:not(.line-focus) {
  filter: blur(0px);
  opacity: 1;
}